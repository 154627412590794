export const CartVue = {
    data(){
        return {
            allCartData: {},
            cartData: new Form({
                qty: 1,
            }),
            selectedColor: {
                id: null,
                code: null,
                price: 0,
                qty: 1,
            },
            selectedSize: {
                id: null,
                code: null,
                price: 0,
                qty: 1,
            },
        }
    },
    created() {
        this.getCartData();
    },
    computed: {
        computedActiveColor() {
            return this.selectedColor.id;
        },
        computedActiveSize() {
            return this.selectedSize.id;
        },
        varPrice() {
            let vPrice;
            if (this.selectedColor.price) {
                vPrice = parseFloat(this.productPrice.displayPrice) + parseFloat(this.selectedColor.price);
            }
            if (this.selectedColor.price) {
                vPrice = parseFloat(this.productPrice.displayPrice) + parseFloat(this.selectedColor.price);
            }

            return {
                onSale: this.productPrice.onSale,
                discount: this.productPrice.discount,
                price: this.productPrice.price,
                displayPrice: vPrice,
            }
        },
    },
    methods: {
        selectColor(id, code, price, qty) {
            this.cartData.reset();
            this.selectedColor = {
                id: id,
                code: code,
                price: price,
                qty: qty,
            };
            this.productQty = qty;
        },
        selectSize(id, code, price, qty) {
            this.cartData.reset();
            this.selectedSize = {
                id: id,
                code: code,
                price: price,
                qty: qty,
            };
            this.productQty = qty;
        },
        getCartData() {
            let existingObj = this;
            axios.get(`${this.baseUrl}/api/getCart`)
                .then(function (response) {
                    existingObj.allCartData = response.data;
                }).catch(error => {
                existingObj.toastAlert(
                    'Shopping cart',
                    error.message,
                    'danger',
                    true
                );
            });
        },
        postCartAdd(id) {
            this.cartData.id = id;
            this.cartData.profile_id = this.productProfileId;
            this.cartData.name = this.productName;
            this.cartData.image = this.productImage;
            this.cartData.color_id = this.selectedColor.id;
            this.cartData.color_code = this.selectedColor.code;
            this.cartData.color_qty = this.selectedColor.qty;
            this.cartData.size_id = this.selectedSize.id;
            this.cartData.size_code = this.selectedSize.code;
            this.cartData.size_qty = this.selectedSize.qty;
            this.cartData.price = this.varPrice.displayPrice;
            let existingObj = this;
            this.cartData.post(`${this.baseUrl}/api/postCartAdd`)
                .then(function (response) {
                    if (response.status && response.status === 200) {
                        existingObj.cartData.reset();
                        existingObj.status = true;
                        existingObj.getCartData();
                    }
                }).catch(error => {
                existingObj.toastAlert(
                    'Add to cart',
                    error.message,
                    'danger',
                    true
                );
            });
        },
        getCartRemove(id) {
            this.editId = id;
            let existingObj = this;
            axios.get(`${this.baseUrl}/api/getCartRemove/${id}`)
                .then(function (response) {
                    if (response.status && response.status === 200) {
                        existingObj.status = true;
                        existingObj.getCartData();
                    }
                }).catch(error => {
                existingObj.toastAlert(
                    'Remove item',
                    error.message,
                    'danger',
                    true
                );
            });
        },
        getCartRemoveAll() {
            let existingObj = this;
            axios.get(`${this.baseUrl}/api/getCartRemoveAll`)
                .then(function (response) {
                    if (response.status && response.status === 200) {
                        existingObj.getCartData();
                    }
                }).catch(error => {
                existingObj.toastAlert(
                    'Remove all',
                    error.message,
                    'danger',
                    true
                );
            });
        },
        showCartModal() {
            this.$refs['cartModal'].show();
        },
        hideCartModal() {
            this.$refs['cartModal'].hide();
        },
        isItemAddedCart(id) {
            const keys = Object.keys(this.allCartData);
            return keys.find((item) => item === id);
        },
        sumCartItems(type) {
            const values = Object.values(this.allCartData);
            let sum = 0;
            values.forEach(item => {
                if (type === 'price') {
                    sum += parseFloat(item.price) * parseInt(item.qty);
                }
                if (type === 'qty') {
                    sum += parseInt(item.qty);
                }
            });
            if (type === 'price') {
                sum = {
                    total: sum,
                    formated: this.currencyFormat(sum),
                };
            }

            return sum;
        },
        currencyFormat(number) {
           return new Intl.NumberFormat('en-LK', { style: 'currency', currency: 'LKR' }).format(
                number,
            )
        }
    },
};
