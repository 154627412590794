import { CartVue } from "./cart";
import Vue from 'vue';

import { BootstrapVue, IconsPlugin, SkeletonPlugin, ModalPlugin } from 'bootstrap-vue';
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(SkeletonPlugin);
Vue.use(ModalPlugin);

//Date format
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
Vue.use(VueFilterDateFormat);

//Import pagination
Vue.component('pagination', require('laravel-vue-pagination'));

//Import v-from
import Form from 'vform'
window.Form = Form;
import {
    HasError,
    AlertError,
    AlertErrors,
    AlertSuccess
} from 'vform/src/components/bootstrap4'
Vue.component(HasError.name, HasError);
Vue.component(AlertError.name, AlertError);
Vue.component(AlertErrors.name, AlertErrors);
Vue.component(AlertSuccess.name, AlertSuccess);

// CKEditor
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
Vue.use( CKEditor );

export const BaseVue = {
    mixins: [CartVue],
    data(){
        let initialState = window.__INITIAL_STATE__;
        return {
            config: { headers: {'content-type': 'multipart/form-data'} },
            baseUrl: initialState.baseUrl || "",
            authId: initialState.authId || null,
            profileId: initialState.profileId || null,
            loading: false,
            loadingTime: 0,
            maxLoadingTime: 1,
            currentPage: 1,
            totalRows: 0,
            perPage: 12,
            filter: null,
            toastCount: 0,
            deletePost: {
                id: '',
                title: '',
            },
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'heading',
                        'bold',
                        'italic',
                        'link',
                    ]
                }
            },
            rowId: null,
            apiStatus: false,
        }
    },
    created() {
        this.$_loadingTimeInterval = null;
    },
    mounted() {
        this.startLoading();
    },
    watch: {
        loading(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.clearLoadingTimeInterval();
                if (newValue) {
                    this.$_loadingTimeInterval = setInterval(() => {
                        this.loadingTime++;
                    }, 1000)
                }
            }
        },
        loadingTime(newValue, oldValue) {
            if (newValue !== oldValue) {
                if (newValue === this.maxLoadingTime) {
                    this.loading = false;
                }
            }
        }
    },
    filters: {
        stringLimit(value, size) {
            if (!value) return '';
            value = value.toString();

            if (value.length <= size) {
                return value;
            }

            return value.substr(0, size) + '...';
        }
    },
    methods: {
        toastAlert(title, message, variant, append= false) {
            this.toastCount++
            this.$bvToast.toast(message, {
                title: title,
                variant: variant,
                appendToast: append,
                autoHideDelay: 10000
            })
        },
        clearLoadingTimeInterval() {
            clearInterval(this.$_loadingTimeInterval);
            this.$_loadingTimeInterval = null;
        },
        startLoading() {
            this.loading = true;
            this.loadingTime = 0;
        },
        getPDFFileType(file) {
            return file.split(".").pop() === 'pdf';
        },
        copyText() {
            let testingCodeToCopy = document.querySelector('#copy-code');
            testingCodeToCopy.setAttribute('type', 'text');
            testingCodeToCopy.select();
            document.execCommand('copy');
            testingCodeToCopy.setAttribute('type', 'hidden');
            window.getSelection().removeAllRanges();
        },
        showDeleteModal(id, title) {
            this.deletePost = { id: id, title: title };
            this.$refs['deleteModal'].show();
        },
        hideDeleteModal() {
            this.deletePost = {}
            this.$refs['deleteModal'].hide();
        },
        showMenusModal() {
            this.$refs['showMenus'].show();
        },
        getImagePath(image, pId, source) {
            if (image) {
                return `storage/app/public/${pId}/${source}/${image}`;
            }
            const defaultImg= source === 'profile' ? 'avatar.png' : 'placeholder.jpg';

            return `public/img/${defaultImg}`;
        },
        numberFormat(number, currency) {
            return new Intl.NumberFormat('en-LK', { style: 'currency', currency: currency }).format(number);
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.status === 0) return 'table-warning'
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        currentRowCount(total) {
            if (total === undefined) {
                return '0 - 0 of 0';
            }
            if (!this.filter) {
                if (total <= this.perPage) {
                    return '0 - ' + total + ' of ' + total;
                }
                return '0 - ' + this.perPage + ' of ' + total;
            }
            return '0 - ' + this.totalRows + ' of ' + total;
        },
        getActionStatus(id) {
            if (this.rowId === id) {
                if (this.apiStatus) {
                    return 'check2-circle'
                }
                return 'exclamation-triangle';
            }
            return '';
        },
    },
};
